import { v4 } from "uuid";

export const USER_POPULAR_QUESTIONS_CONFIG: {
  id: string;
  title: string;
  content: string;
}[] = [
  {
    id: v4(),
    title: "Для чого проходити це тестування?",
    content:
      "Уповноважена особа проходить тестування для здійснення своїх функцій, визначених Законом України “Про публічні закупівлі”, результатами тестування підтверджує свій рівень володіння необхідними (базовими) знаннями у сфері публічних закупівель. ",
  },
  {
    id: v4(),
    title:
      "Скільки правильних відповідей потрібно надати, щоб скласти тестування?",
    content:
      "Хоча б 30 правильних відповідей з 50. У кожному питанні лише одна правильна відповідь.",
  },
  {
    id: v4(),
    title: "Як часто я можу перескладати тестування?",
    content: "Між спробами має пройти 72 години.",
  },
  {
    id: v4(),
    title: "Чи потрібно відповідати на всі запитання?",
    content:
      "Ні, ви можете завершити тестування у будь-який момент. Чим більше відповідей ви надасте, тим більша ймовірність успішного проходження тестування.",
  },
  {
    id: v4(),
    title: "Чи можу я пропускати питання і повернутись до них пізніше?",
    content:
      "Так, ви може відповідати на питання послідовно або в довільному порядку.",
  },
  {
    id: v4(),
    title: "Чи можу я завершити тестування швидше ніж за 60 хвилин?",
    content: "Ви можете завершити тестування у будь-який момент.",
  },
  {
    id: v4(),
    title: "Що станеться, якщо я вийду з тестування не завершивши його?",
    content:
      "Ви маєте можливість повернутись до тестування та продовжити, поки не закінчився час на його проходження. Якщо ви повернетесь до тестування, коли його час закінчився, ви можете переглянути результати, наклавши електронний підпис.",
  },
  {
    id: v4(),
    title: "Що робити після успішного проходження?",
    content:
      "Додатково нічого робити не потрібно. Після успішного проходження тестування майданчик замовника при вході в кабінет користувача, починаючи з 01.01.2022, буде робити перевірку, чи має користувач підтверджений рівень знань для виконання дій передбачених законом України “Про публічні закупівлі” та виконання покладених замовником обов’язків на уповноважену особу.",
  },
  {
    id: v4(),
    title:
      "Я успішно пройшов тестування, а не роздрукував сертифікат. Що робити?",
    content:
      "Ви можете увійти в модуль тестування, переглянути результати останнього успішного тестування та роздрукувати сертифікат. ",
  },
  {
    id: v4(),
    title: "Коли потрібно буде проходити тестування повторно?",
    content:
      "Результати тестування уповноваженою особою не обмежені в часі, це відповідає поточній редації Закону України “Про публічні закупівлі”.",
  },
  {
    id: v4(),
    title: "Мені потрібно передати результати на майданчик?",
    content:
      "Ні, перевірка результатів тестування уповноважених осіб на майданчику проходить автоматично. Це відбувається після авторизації уповноваженої особи на майданчику за допомогою електронного підпису шляхом взаємодії з модулем тестування.",
  },
];

export const RESULT_VERIFICATION_POPULAR_QUESTIONS_CONFIG: {
  id: string;
  title: string;
  content: string;
}[] = [
  {
    id: v4(),
    title: "Що таке РНОКПП і де його взяти?",
    content:
      "РНОКПП (раніше – ідентифікаційний код чи ІПН) реєстраційний номер облікової картки платника податків. Може міститися на довідці про присвоєння такого номеру фізичній особі, на ID-картці. Реєстраційний номер облікової картки платника податків (РНОКПП) зазначається десятьма цифрами.",
  },
  {
    id: v4(),
    title: "Якщо я не маю коду РНОКПП, що мені вводити?",
    content:
      "В разі відсутності коду РНОКПП з релігійних мотивів або за наявності інших причин відмови від РНОКПП, для фізичних осіб, які мають відмітку в паспорті про наявність у них права здійснювати будь-які платежі за серією та номером паспорта, зазначаються серія та номер паспорта.",
  },
  {
    id: v4(),
    title: "Для чого проводиться перевірка?",
    content:
      "Перевірка результатів тестування може знадобитися для того, щоб довести, наприклад, роботодавцю уповноваженої особи про успішні результати тестування.",
  },
  {
    id: v4(),
    title: "Де можна пройти тестування?",
    content:
      "Щоб пройти тестування поверніться на головну сторінку Prozorro, перейдіть в розділ [“Тестування уповноважених осіб”] (посилання) та оберіть [“Увійти до модулю тестування”].",
  },
  {
    id: v4(),
    title: "Де отримати сертифікат про проходження тестування?",
    content:
      "Уповноважена особа, що успішно пройшла тестування, може отримати сертифікат в особистому кабінеті модуля тестування.",
  },
  {
    id: v4(),
    title:
      "Я успішно пройшов тестування, а не роздрукував сертифікат. Що робити?",
    content:
      "Ви можете увійти в модуль тестування, переглянути результати останнього успішного тестування та роздрукувати сертифікат.",
  },
  {
    id: v4(),
    title: "Коли потрібно буде проходити тестування повторно? ",
    content:
      "Результати тестування уповноваженою особою не обмежені в часі, це відповідає поточній редації Закону України “Про публічні закупівлі”.",
  },
  {
    id: v4(),
    title: "Мені потрібно передати результати на майданчик?",
    content:
      "Ні, перевірка результатів тестування уповноважених осіб на майданчику проходить автоматично. Це відбувається після авторизації уповноваженої особи на майданчику за допомогою електронного підпису шляхом взаємодії з модулем тестування.",
  },
];
