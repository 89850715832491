


















import { Emit } from "vue-property-decorator";
import Component, { mixins } from "vue-class-component";
import FormFieldMixin from "@/mixins/FormFieldMixin";
import { InputValueType } from "@/types/form/InputValueType";

@Component
export default class InputField extends mixins(FormFieldMixin) {
  @Emit()
  public input({ target }: { target: HTMLInputElement }): InputValueType {
    return target.value;
  }
}
