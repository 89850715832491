


























































































import { CHECK_VERIFICATION } from "@/api";
import { rnokppConfig } from "@/config/rnokppConfig";
import Component, { mixins } from "vue-class-component";
import { ScriptReceiver } from "@/services/DOM/ScriptReceiver";
import { DateFormatPreview } from "@/services/DateFormat/DateFormatPreview";
import BuildModeMixin from "@/mixins/BuildModeMixin";
import SwitchContent from "@/components/UI/SwitchContent.vue";
import InputField from "@/components/form/InputField.vue";
import VueRecaptcha from "vue-recaptcha";
import AnswerStatusBar from "@/components/UI/AnswerStatusBar.vue";
import { RESULT_VERIFICATION_POPULAR_QUESTIONS_CONFIG } from "@/config/userPopularQuestionsConfig";

const scriptReceiver = new ScriptReceiver();

@Component({
  filters: {
    dateFormat(dateStr: string): string {
      return DateFormatPreview.getDayShotMouthYear(dateStr);
    },
  },
  components: {
    InputField,
    VueRecaptcha,
    SwitchContent,
    AnswerStatusBar,
  },
})
export default class ResultVerification extends mixins(BuildModeMixin) {
  public readonly popularQuestionsConfig =
    RESULT_VERIFICATION_POPULAR_QUESTIONS_CONFIG;
  public readonly recaptchaKey = process.env.VUE_APP_RECAPTCHA_KEY || "";
  public isCaptchaSolved = false;
  public drfoError = "";
  public form = {
    drfo: "",
    recaptchaResponse: "",
  };
  public certificate: Record<string, any> = {
    data: null,
    user: "",
  };

  async mounted(): Promise<void> {
    const recaptchaUrl = process.env.VUE_APP_RECAPTCHA_URL || "";

    if (recaptchaUrl !== "") {
      await scriptReceiver.insert(recaptchaUrl, true, true);
    }
  }

  get isPublicEnv(): boolean {
    return this.isProduction || this.isStaging;
  }

  get isValidRnokpp(): boolean {
    return this.form.drfo.length > rnokppConfig.length;
  }

  get isSendFormBtnDisabled(): boolean {
    const isSomeError = !this.isValidRnokpp || !this.isCaptchaSolved;

    return (isSomeError && this.isPublicEnv) || this.drfoError !== "";
  }

  recaptchaVerify(event: string): void {
    this.form.recaptchaResponse = event;
    this.isCaptchaSolved = true;
  }

  recaptchaExpired(): void {
    this.form.recaptchaResponse = "";
    this.isCaptchaSolved = false;
  }

  inputHandler(): void {
    this.drfoError = "";
  }

  async sendForm(): Promise<void> {
    try {
      const { certified_at } = await CHECK_VERIFICATION(this.form);
      this.certificate.data = certified_at;
      this.certificate.user = this.form.drfo;
    } catch ({
      errors: {
        drfo: [firstError],
      },
    }) {
      this.certificate.user = "";
      this.drfoError = firstError;
    } finally {
      window.grecaptcha.reset();
      this.isCaptchaSolved = false;
      this.form.drfo = "";
    }
  }
}
