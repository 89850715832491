



















import { Component, Vue, Prop } from "vue-property-decorator";
import MaterialIcon from "@/components/UI/MaterialIcon.vue";

const TYPES: Record<string, { class: string; icon: string }> = {
  success: {
    class: "success",
    icon: "check_circle",
  },
  error: {
    class: "error",
    icon: "highlight_off",
  },
  info: {
    class: "info",
    icon: "info",
  },
};

@Component({
  components: { MaterialIcon },
})
export default class AnswerStatusBar extends Vue {
  @Prop({ type: String, default: TYPES.info }) type!: string;

  get typeConfig(): { class: string; icon: string } {
    return TYPES[this.type] || TYPES.info;
  }
}
