import { Vue, Component, Prop } from "vue-property-decorator";
import { FormFieldType } from "@/types/form/FormFieldType";
import { InputValueType } from "@/types/form/InputValueType";

@Component
export default class FormFieldMixin extends Vue implements FormFieldType {
  @Prop({ type: [String, Number, Boolean], default: "" })
  value!: InputValueType;
  @Prop({ type: String, default: "" }) readonly id!: string;
  @Prop({ type: Boolean, default: false }) readonly disabled!: boolean;
  @Prop({ type: [Array, String], default: "" }) readonly inputClass!: string;
  @Prop({ type: String, default: "" }) readonly placeholder!: string;
  @Prop({ type: String, default: "text" }) readonly type!: string;
}
