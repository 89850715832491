





















import { Component, Vue } from "vue-property-decorator";
import MaterialIcon from "@/components/UI/MaterialIcon.vue";

@Component({
  components: { MaterialIcon },
})
export default class SwitchContent extends Vue {
  public showBodyContent = false;

  public onToggle(): void {
    this.showBodyContent = !this.showBodyContent;
  }
}
